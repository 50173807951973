import React from 'react';
import appImg1 from '../../assets/images/app-img1.png';
import appImg2 from '../../assets/images/app-img2.png';

function DownloadBlock({ className }) {
    return (
        <>
            <section id="download"  className={`appie-download-area ${className || ''}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <span>Download our app</span>
                                <br />
                                {/* <span>FOR FREE!</span> */}
                                <h3 className="appie-title">Simple and elegant</h3>
                                {/* <a href="https://play.google.com/store/apps/details?id=com.mymoneypanda.app" className="main-btn"> */}
                                <div className='download-buttons'  >
                                    <a href="https://play.google.com/store/apps/details?id=in.applore.mymoneypanda&pli=1" className="main-btn">
                                        Download from Play Store
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=in.applore.mymoneypanda&pli=1" className="main-btn">
                                        Download from App Store
                                    </a>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-download-content">
                                <div className="imgBox">
                                    <img src={appImg1} className="imgOne" alt="" />
                                    <img src={appImg2} className="imgTwo" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DownloadBlock;
